export const powerUseMockData = {
    data: [
        {
            label: "Idle",
            value: 73
        },
        {
            label: "Charging",
            value: 14
        },
        {
            label: "Working",
            value: 14
        }
    ],
    layout: { height: 200, width: 200 }
};
export const batteryPowerUseMockData = {
    data: [
        {
            label: "Idle",
            value: 72
        },
        {
            label: "Charging",
            value: 14
        },
        {
            label: "Discharging",
            value: 14
        }
    ],
    layout: { height: 200, width: 200 }
};
