// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.battery-life {
    background-color: #f5f5f5;
    margin-top: 1rem;
}

.charge-history {
    line-height: 0.5rem;
    color: blue;
    font-size: 0.75rem;
    text-align: right;
}

.timeline-container-height {
    height: 28rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Projects/AssetPageDetail.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,iBAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".battery-life {\n    background-color: #f5f5f5;\n    margin-top: 1rem;\n}\n\n.charge-history {\n    line-height: 0.5rem;\n    color: blue;\n    font-size: 0.75rem;\n    text-align: end;\n}\n\n.timeline-container-height {\n    height: 28rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
