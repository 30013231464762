import { jsx as _jsx } from "react/jsx-runtime";
import Plot from "react-plotly.js";
export const PieChart = (props) => {
    const data = [
        {
            labels: props.labels,
            marker: {
                colors: props.colors
            },
            textinfo: "none",
            type: "pie",
            values: props.values
        }
    ];
    return (_jsx(Plot, { config: { staticPlot: true }, data: data, layout: {
            height: props.layout.height,
            legend: { orientation: "h", xanchor: "center" },
            margin: { b: 20, l: 0, r: 0, t: 10 },
            showlegend: true,
            width: props.layout.width
        } }));
};
